
// Vue reactivity
import { computed, ref } from 'vue';

// icons
import { } from 'ionicons/icons';

// components
import { IonPage, IonContent, IonGrid, IonRefresher, IonRefresherContent, } from '@ionic/vue';

import PageHeader from "@/components/PageHeader.vue";
import SwiperCategoryData from "@/components/slides/SwiperCategoryData.vue";
import LoadingSkeleton from '@/components/LoadingSkeleton.vue';

// API services
import PostService from '@/services/PostService';

import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { utils } from '@/composables/utils';

export default {
  name: 'PostListPage',
  components: { IonPage, IonContent, IonGrid, IonRefresher, IonRefresherContent,
                PageHeader, SwiperCategoryData, LoadingSkeleton },
  setup() {
    const { t } = useI18n();
    const { getLocalizedStr, } = utils();
    const store = useStore();

    // 1. declare state variables (ref to make them reactive)
    const loading = computed(() => store.state.loadingAppPublicData);
    const allPosts = computed(() => store.state.allPosts);
    const allPostCategories = computed(() => store.state.allPostCategories);

    // 2. fetch data from API and store data in state variables
    const fetchAllPosts = (e: any = null) => {
      PostService.getAllPosts().then(res => {
        store.commit('receiveAllPosts', res);
        if (e) e.target.complete(); // dismiss ion-refresher
      });
    };

    // 3. return variables & methods to be used in template HTML
    return {
      // icons

      // variables
      loading, allPosts, allPostCategories,

      // methods
      t, getLocalizedStr,
      fetchAllPosts,
    }
  }
}
