import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import 'firebase/storage';
import Compressor from 'compressorjs'; // for compressing image size before upload
import { Photo } from '@/composables/usePhotoGallery';

const uploadImage = async (imageBase64Data: any, imageTitle: string, targetTable = 'post') => {
  // compress the image
  const response = await fetch(imageBase64Data);
  const blob = await response.blob();
  const imageFile: any = await new Promise((resolve, reject) => {
    new Compressor(blob, {
      maxWidth: 1500,
      quality: 0.6,
      success: resolve,
      error: reject,
    });
  });
  // upload the post image file to Firebase Storage
  const extension = imageBase64Data.split(';')[0].split('/')[1];
  const fileName = `${new Date().valueOf()}-${imageTitle.substring(0, 10)}.${extension}`;
  const snapshot = await firebase.storage().ref(`${targetTable}-${fileName}`).put(imageFile);
  return await snapshot.ref.getDownloadURL();
}

export default {
  // READ
  async getAllPosts() {
    return (await firebase.app().functions('asia-east2').httpsCallable('post-getAllPosts')()).data;
  },
  async getPostById(id: any) {
    const res = await firebase.app().functions('asia-east2').httpsCallable('post-getPostById')({ id });
    return res.data[0];
  },
  async getPostComments(postId: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('post-getPostComments')({ postId })).data;
  },

  // UPDATE
  async updatePost(postId: any, postTitle: string, postContent: string) {
    return await firebase.app().functions('asia-east2').httpsCallable('post-updatePost')({ postId, postTitle, postContent });
  },
  async updatePostReaction(postId: any, reaction = "like") {
    return await firebase.app().functions('asia-east2').httpsCallable('post-updatePostReaction')({ postId, reaction });
  },
  async updatePostCommentReaction(commentId: any, reaction = "like") {
    return await firebase.app().functions('asia-east2').httpsCallable('post-updatePostCommentReaction')({ commentId, reaction });
  },

  // CREATE
  async addNewPost(postTitle: any, postContent: any, photos: Photo[]) {
    const photoLinks = []; // image URL
    if (photos) {
      for (const photo of photos) {
        const link = await uploadImage(photo.base64Data, postTitle);
        photoLinks.push(link);
      }
    }
    return await firebase.app().functions('asia-east2').httpsCallable('post-addNewPost')({ postTitle, postContent, photoLinks });
  },
  async addNewPostComment(postId: any, content: any) {
    return await firebase.app().functions('asia-east2').httpsCallable('post-addNewPostComment')({ postId, content });
  },

  // DELETE
  async deletePost(postId: any, postImageLink: any) {
    if (postImageLink) firebase.storage().refFromURL(postImageLink).delete(); // delete the post image from Firebase Storage
    return await firebase.app().functions('asia-east2').httpsCallable('post-deletePost')({ postId });
  }
}